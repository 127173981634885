

















































































import BopModel from "@/models/BopModel";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import OperationTypeService from "@/services/OperationTypeService";
import OperationService from "@/services/OperationService";

@Component({})
export default class OperationFamilyFilterComponent extends Vue {
  private operationFamilies: any = {};
  @Prop({default: ()=> false})
  private readonly  isFirstLevelDisabled!:boolean;
  @Prop({default: ()=> ""})
  private readonly  rootFamilyId!:string;
  private lvl1: any = null;
  private lvl2: any = null;
  private lvl3: any = null;

  private selectedFamilyId: string = "";
  private selectedOperation: any = {};
  private operationsDictionary: any = {};
  private operations: Array<any> = [];
  private get selectedBopModel(): BopModel {
    return this.$store.state.selectedBopModel;
  }

  /**
   * methods
   */
  private getOperationFamilyLevel(el: any) {
    return el[Object.keys(el)[0]]
      ? this.$t(
          `operationFamilyLevel.${el[Object.keys(el)[0]].levelNameKey}`
        ).toString()
      : "";
  }
  private async onSelectFamilyChange(caller) {
    let selectedFamily = this.lvl1 ?? null;
    if (caller === "lvl1") {
      this.lvl2 = null;
    } else if (caller === "lvl2") {
      this.lvl3 = null;
    }
    if (this.lvl2 !== null) {
      selectedFamily = this.lvl2;

      if (this.lvl3 !== null) {
        selectedFamily = this.lvl3;
      }
    }
    this.$emit('operations-family-changed',{ level:caller,operationFamilyId: selectedFamily?.id});
    if (!selectedFamily) return;
    this.selectedFamilyId = selectedFamily.id;
    if (!this.operationsDictionary[this.selectedFamilyId]) {
      this.operationsDictionary[this.selectedFamilyId] = (
          await OperationService.getBopOperations(
              this.$store.state.selectedBopModel.id,
              1,
              this.$i18n.locale,
              null,
              null,
              this.selectedFamilyId
          )
      ).data.map((el: any) => ({
        key: el,
        value: el.name,
      }));
      this.$forceUpdate();
    }

    this.operations = this.operationsDictionary[this.selectedFamilyId];


  }
  private onSelectOperationChange(selectedOperation) {
    this.selectedOperation = selectedOperation;
  }
  private operationPreAdd() {
    this.$emit("operationPreAdd", this.selectedOperation);
  }

  /**
   * Hooks
   */
  private async created() {
    this.operationFamilies = (
        await OperationTypeService.ListOperationTypesWithDescendenants(
            1,
            this.selectedBopModel.id,
            this.$i18n.locale
        )
    ).data;
    if (this.rootFamilyId.length && this.operationFamilies[this.rootFamilyId]) {
      this.lvl1 = this.operationFamilies[this.rootFamilyId];
    }
  }
  @Watch("rootFamilyId")
  onRootFamilyIdChange() {
    if (this.rootFamilyId.length && this.operationFamilies[this.rootFamilyId]) {
      this.lvl1 = this.operationFamilies[this.rootFamilyId];
    }
  }

}
